<template>
 <div class="form-group">
  <select class="form-control icon-right time-select" v-model="timespan" @change="changeSelectedOption">
    <option v-for="option in options" :value="option.value">{{option.label}}</option>
  </select>
  <span class="material-icons time-icon right">watch_later</span>
</div>
</template>

<script>
  export default {
    name: 'TimeSpanSelector',
    props: {
      timeframe: {
        type: String,
      },
    },
    data: () => ({
      timespan: null,
      options : [{label:"Uhrzeit",value:null},{label:"Vormittags (8:00-12:00)",value:"vormittags"},{label:"Nachmittags (12:00-17:00)",value:"nachmittags"},{label:"Abends (ab 17:00)",value:"abends"}],
    }),
    watch: {
      timeframe: {
        handler: function(value) {
         this.timespan = this.timeframe;
       },
       immediate: true
     },
   },
   methods: {
    changeSelectedOption(){
      this.$emit('changedSelectedTimeSpan',this.timespan);
    }
  }
}
</script>

<style lang="scss" scoped>
  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  .time-select {
    @media(max-width:991px){
      width: 165px !important;
      padding: 0 !important;
      border: 1px solid #ced4da !important;
      border-radius: 0.25rem;
    }
  }

  .time-icon {
   @media(max-width:991px){
    position: relative;
    right: auto !important;
  }
}
</style>
